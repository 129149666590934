import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PARTIAL_ROUTES_SCHEDULER } from './shared/constants';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { RoutePage } from './shared/enums/routes.enum';

const routes: Routes = [
  { path: '', redirectTo: RoutePage.LANDING_PAGE, pathMatch: 'full' },
  {
    path: PARTIAL_ROUTES_SCHEDULER,
    loadChildren: () =>
      import('./scheduler/scheduler.module').then((s) => {
        return s.SchedulerModule;
      }),
  },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', redirectTo: RoutePage.LANDING_PAGE, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
