<div class="grid1">
  <div
    *ngIf="locationInsuranceRequired() !== insuranceDropdownVisibility.HIDDEN"
  >
    <eos-mat-select
      id="insuranceCarriers"
      [values]="insurancesDropdown"
      [defaultSelect]="true"
      [loadValue]="selectedInsurance"
      (valueChange)="updateInsurance($event)"
      [disabled]="false"
      [required]="
        locationInsuranceRequired() === insuranceDropdownVisibility.REQUIRED
      "
      [label]="insuranceCarrierLabel"
    >
    </eos-mat-select>
  </div>
  <div>
    <eos-mat-select
      id="providers"
      [values]="providersDropdown"
      [defaultSelect]="true"
      [loadValue]="selectedProvider"
      (valueChange)="updateProvider($event)"
      [disabled]="false"
      [required]="true"
      [label]="appointmentWithDoctorLabel"
    >
    </eos-mat-select>
  </div>
</div>
