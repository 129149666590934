import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { NavService } from './core/services/nav.service';
import { AppInitService } from './app-init.service';
import { RouterOutlet } from '@angular/router';
import { ViewTitleHeaderComponent } from './shared/components/view-title-header/view-title-header.component';
import { ShortMessageComponent } from './shared/components/short-message/short-message.component';
import { PracticeHeaderComponent } from './shared/components/practice-header/practice-header.component';
import { NavSchedulingService } from './scheduler/services/nav-scheduling.service';
import { NgIf } from '@angular/common';
import { AppConfigService } from './core/services/app-config.service';

/**
 * Root component for the application
 */
@Component({
  selector: 'eos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PracticeHeaderComponent,
    ShortMessageComponent,
    ViewTitleHeaderComponent,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  // This feeds the title of the current view to the ViewTitleHeaderComponent
  viewTitle = '';

  constructor(
    private logger: NGXLogger,
    private navService: NavService,
    private navSchedulingService: NavSchedulingService,
    private appInitService: AppInitService,
    private appConfigService: AppConfigService,
  ) {}

  // We don't come here until app-init.service.ts has finished initializing.
  ngOnInit(): void {
    this.logger.info('AppComponent initialized');
    if (this.appInitService.getInitialized()) {
      // Disable external stylesheets
      this.disableExternalStylesheets();
      this.navService.navigateToPage(this.navSchedulingService.getFirstRoute());
    } else {
      this.navService.navigateToErrorPage();
    }

    // Subscribe to viewTitle observable. It is updated by each view component.
    this.appConfigService.getViewTitle$().subscribe((data) => {
      setTimeout(() => {
        this.viewTitle = data;
      });
    });
  }

  // We need to disable stylesheets loaded from index.html to avoid conflicts
  // with styles being used by the application
  private disableExternalStylesheets(): void {
    const allStylesheets = document.styleSheets;

    // Filter stylesheets containing 'material' in their href
    const externalStylesheets = Array.from(allStylesheets).filter(
      (sheet) =>
        sheet.href &&
        (sheet.href.includes('googleapis.com') ||
          sheet.href.includes('getmdl.io')),
    );

    // Disable the matching stylesheets
    externalStylesheets.forEach((sheet) => {
      this.logger.debug('Disabling stylesheet:', sheet.href);
      sheet.disabled = true;
    });
  }
}
