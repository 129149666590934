import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HttpErrorResponse } from '@angular/common/http';
import { NavService } from './nav.service';

/**
 * Global error handler service
 */
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): void {
    const navService = this.injector.get(NavService);
    const logger = this.injector.get(NGXLogger);

    logger.error(error);
    navService.navigateToErrorPage();
  }
}
