import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'eos-pe-checkbox-toggle',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf],
})
export class CheckboxComponent implements AfterViewInit, OnChanges {
  @ViewChild('checkbox') checkboxInput: ElementRef;
  @Input() isOn: boolean;
  @Input() labelName: string;
  @Input() disabled: boolean;
  @Output() checkboxEmitter: EventEmitter<boolean>;
  environment = environment;

  constructor() {
    this.checkboxEmitter = new EventEmitter<boolean>();
  }

  ngAfterViewInit(): void {
    this.ngOnChanges();
  }

  ngOnChanges(): void {
    if (this.isOn && this.checkboxInput) {
      this.checkboxInput.nativeElement.checked = true;
    }
  }

  toggle(): void {
    if (this.isOn === undefined) {
      this.isOn = false;
    }
    this.isOn = !this.isOn;
    if (this.checkboxInput.nativeElement.checked !== this.isOn) {
      this.checkboxInput.nativeElement.checked = this.isOn;
    }
    this.checkboxEmitter.emit(this.isOn);
  }
}
