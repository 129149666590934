import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Portal } from '../../shared/domains/scheduler/portal';
import { Scheduler } from '../../shared/domains/scheduler/scheduler';
import { HateoasLink } from '../../shared/domains/scheduler/hateoas-link';

/**
 * This service is used to store some application-level properties.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private portalObj: Portal;
  private schedulerObj: Scheduler;
  private practiceName: string;
  private viewTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getPortal(): Portal {
    return this.portalObj;
  }

  setPortal(portalObj: Portal): void {
    this.portalObj = portalObj;
    this.practiceName = portalObj.practiceName;
  }

  getScheduler(): Scheduler {
    return this.schedulerObj;
  }

  setScheduler(schedulerObj: Scheduler): void {
    this.schedulerObj = schedulerObj;
  }

  getPortalObjLink(rel: string): string | null {
    return this.getHrefByRel(this.portalObj.links, rel);
  }

  getSchedulerObjLink(rel: string): string | null {
    return this.getHrefByRel(this.schedulerObj.links, rel);
  }

  getPracticeName$(): Observable<string> {
    // This only happens once so using 'of' is fine
    return of(this.practiceName);
  }

  setViewTitle(viewTitle: string): void {
    this.viewTitle.next(viewTitle);
  }

  getViewTitle$(): Observable<string> {
    return this.viewTitle.asObservable();
  }

  private getHrefByRel(
    links: Array<HateoasLink>,
    relValue: string,
  ): string | null {
    const link = links.find((link) => link.rel === relValue);
    return link ? link.href : null;
  }
}
