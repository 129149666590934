import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { LogTransactionService } from '../services/log-transaction.service';

@Injectable()
export class TransactionInterceptor implements HttpInterceptor {
  constructor(
    private logTransactionService: LogTransactionService,
    private logger: NGXLogger,
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const tId: string = this.logTransactionService.getLogTransactionId();

    // Clone the request to add the new header.
    const reqClone = req.clone({
      setHeaders: {
        LOG_TRANSACTION_ID: tId,
      },
    });

    return next.handle(reqClone);
  }
}
