import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizeAndSpaceCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const regex = /[A-Z]/g;
      const newValue = value.replace(regex, ' $&');
      return newValue.charAt(0).toUpperCase() + newValue.slice(1).toString();
    } else {
      return value;
    }
  }
}
