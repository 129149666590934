import { Component, Input } from '@angular/core';

/**
 * Component to display the title of the current view
 * It is driven from AppComponent which subscribes to the viewTitle observable.
 */
@Component({
  selector: 'eos-view-title-header',
  standalone: true,
  templateUrl: './view-title-header.component.html',
  styleUrl: './view-title-header.component.scss',
})
export class ViewTitleHeaderComponent {
  @Input() viewTitle = '';
}
