import { Component, OnInit } from '@angular/core';
import { Translations } from '../../core/services/translations.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'eos-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class ErrorPageComponent implements OnInit {
  errorPageLanguage;

  constructor(
    private logger: NGXLogger,
    private translations: Translations,
  ) {}

  ngOnInit(): void {
    this.logger.debug('ErrorPageComponent.ngOnInit');
    this.errorPageLanguage = this.translations.errorPage;
  }
}
