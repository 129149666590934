import { Component, OnInit, Type } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocationLayoutComponent } from '../../../pages/location-layout/location-layout.component';
import { AppointmentDetailsService } from '../../../core/services/appointment-details.service';
import { ServiceViewComponent } from '../../../pages/service-view/service-view.component';
import { NgIf } from '@angular/common';
import { JsonContentService } from '../../../scheduler/services/json-content.service';

/**
 * This component shows optional content on the first displayed view based on scheduler config type #10.
 */
@Component({
  selector: 'eos-short-message',
  standalone: true,
  imports: [NgIf],
  templateUrl: './short-message.component.html',
  styleUrl: './short-message.component.scss',
})
export class ShortMessageComponent implements OnInit {
  isVisible: boolean = false;
  content: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appointmentDetailsService: AppointmentDetailsService,
    private jsonContentService: JsonContentService,
  ) {}

  ngOnInit(): void {
    // Subscribe to router events to detect navigation changes
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.handleNavigationEnd());

    this.content = this.jsonContentService.getContent(
      JsonContentService.content.SHORT_MESSAGE,
    );
  }

  // When we've navigated to a new page, check if this component should be visible
  private handleNavigationEnd(): void {
    // Get the current component and update visibility
    const currentComponent = this.getCurrentComponent();
    this.updateVisibility(currentComponent);
  }

  // This method is used to get the current component that is being displayed
  private getCurrentComponent(): Type<unknown> | null {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.component;
  }

  // This component may be visible with either the Locations view or the Services view
  private updateVisibility(component: Type<unknown> | null): void {
    if (this.content === '') {
      this.isVisible = false;
      return;
    }
    if (
      (component === LocationLayoutComponent &&
        !this.appointmentDetailsService.isSingleLocation) ||
      (component === ServiceViewComponent &&
        this.appointmentDetailsService.isSingleLocation)
    ) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
}
