import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTime24hTo12h',
  standalone: true,
})
export class ConvertTime24hTo12hPipe implements PipeTransform {
  zeroPaddedHour = false;

  transform(time: string): string {
    let hour = Number(time.split(':')[0]);

    const min = time.split(':')[1];
    const part = hour >= 12 ? 'PM' : 'AM';

    if (hour === 0) {
      hour = 12;
    }
    hour = hour > 12 ? hour - 12 : hour;

    return `${hour}:${this.formatPrefix(min)} ${part}`;
  }

  formatPrefix(time: number | string): string {
    return time.toString().padStart(2, '0');
  }
}
