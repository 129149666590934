import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { DropdownValueObject } from './dropdown-value-object';
import { NGXLogger } from 'ngx-logger';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';

export const DEFAULT_SELECT_VALUE = '<Select>';

@Component({
  selector: 'eos-mat-select',
  templateUrl: './app-mat-select.component.html',
  styleUrls: ['./app-mat-select.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    TranslateModule,
  ],
})
export class AppMatSelectComponent implements OnInit, OnChanges {
  defaultSelectValue = DEFAULT_SELECT_VALUE;
  dropdownButton: ElementRef;

  /**
     // Emitter to announce to other listeners that a loadValue change has occurred.
     */
  @Output() valueChange: EventEmitter<DropdownValueObject>;

  /**
     // The current selection of the dropdown item
     */
  @Output() selectedValue: DropdownValueObject;

  /**
     // Collection of dropdownValues that make up the dropdown list
     */
  @Input() values: DropdownValueObject[];

  /**
   * Make the dropdown disabled to prevent it from being changed
   */
  @Input() disabled = false;

  /**
     // If we want to preload or assign a loadValue to a dropdown then we can use this.
     */
  @Input() loadValue: DropdownValueObject | null;

  /**
     // Will the default loadValue for this dropdown be <Select>?  Defaults to false
     */
  @Input() defaultSelect = false;

  /**
   * determines if the dropdown menu is in an error state
   * @type {boolean}
   */
  @Input() isInvalid = false;

  /**
   * If the component isInvalid pass in an optional message that will popup when hovering over the dropdown
   */
  @Input() invalidMessage = 'This value is invalid';

  @Input() required = true;

  @Input() label: string;

  constructor(private logger: NGXLogger) {
    this.valueChange = new EventEmitter<DropdownValueObject>();
  }

  /*Determine if we want <Select> to get added to the data and assigns first time in collection to the selectedValue.*/
  ngOnInit(): void {
    this.logger.debug('AppMatSelectComponent: in ngOnInit');
    if (
      this.values &&
      this.defaultSelect &&
      this.selectedValue?.value !== DEFAULT_SELECT_VALUE
    ) {
      this.selectedValue = new DropdownValueObject(DEFAULT_SELECT_VALUE);
    }

    if (this.loadValue) {
      this.selectedValue = this.loadValue;
    } else if (this.values) {
      if (!this.selectedValue) {
        this.selectedValue = this.values[0];
      }
    } else {
      this.logger.error('Dropdown list cannot be empty!');
    }
  }

  onSelectedValue(selectedValue): void {
    this.selectedValue = selectedValue;
    this.logger.debug('onSelectedValue():', this.selectedValue);
    this.valueChange.emit(this.selectedValue);
  }

  /**
   * This fires off anytime a change is made to any dropdown menu which can be quite frequent however we are
   * only interested in a very particular type of change.  That is when the loadValue gets changed after a dropdown
   * has been initialized.  The load value is used to programmatically set currently selected dropdown w/o emitting
   * a change event.
   * @param changes
   */
  ngOnChanges(): void {
    this.disabled = false;
    if (this.values?.length === 1 || this.values?.length === 0) {
      this.disabled = true;
      if (this.values?.length === 1) {
        this.selectedValue = this.values[0];
      } else {
        this.selectedValue = {
          value: 'no' + this.label,
          label: 'no' + this.label,
        };
        this.logger.error('Dropdown options is not available');
      }
    } else if (this.loadValue) {
      this.selectedValue = this.loadValue;
    }
  }

  /**
   * Called by the dom when a new dropdown menu item has been selected
   * @param value
   * @param selectedIndex
   */
  selectDropdown(value: DropdownValueObject): void {
    this.selectedValue = value;
    this.valueChange.emit(value);
    this.dropdownButton.nativeElement.focus();
  }

  uniqueItemIdentifier(
    option: DropdownValueObject,
    item: DropdownValueObject,
  ): boolean {
    return option?.value === item?.value; // Use a unique identifier of your objects
  }
}
