<div class="main-content">
  <div
    class="location-card"
    (click)="selectLocation(address)"
    (keydown)="handleKeydown($event, address)"
    *ngFor="let address of officeList"
    tabindex="0"
  >
    <div class="content">
      <div class="item">
        <div class="item-title">
          {{ address.displayName }}
        </div>
        <div class="item-address">
          {{ address.officeAddress.address1 }}<br />
          {{
            address.officeAddress.city +
              ', ' +
              address.officeAddress.state +
              ' ' +
              address.officeAddress.zipCode.substring(0, 5)
          }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="back-button">
  <button
    class="buttonBack"
    mat-stroked-button
    color="primary"
    (click)="goToHomePage()"
  >
    {{ serviceViewPageLanguage.back | translate }}
  </button>
</div>
