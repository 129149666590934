import { Injectable } from '@angular/core';
import { AppConfigService } from '../../core/services/app-config.service';
import { LanguageService } from '../../core/services/language.service';
import { NGXLogger } from 'ngx-logger';

/**
 * This service is used to retrieve JSON content from base64 encoded strings
 * for site-message and short-message.
 */
@Injectable({
  providedIn: 'root',
})
export class JsonContentService {
  // Constants for message types, representing valid property and messageKey pairs
  static readonly content = {
    SHORT_MESSAGE: {
      property: 'allowCustomTerms',
      key: 'shortMessage',
    },
    PATIENT_INFO_1: {
      property: 'allowCustomTerms',
      key: 'patientInfo1',
    },
    PATIENT_INFO_2: {
      property: 'allowCustomTerms',
      key: 'patientInfo2',
    },
    SITE_MESSAGE: { property: 'siteMessage', key: 'siteMessage' },
  };

  constructor(
    private logger: NGXLogger,
    private appConfigService: AppConfigService,
    private languageService: LanguageService,
  ) {}

  getContent(messageType: { property: string; key: string }): string {
    let message = '';
    const schedObj = this.appConfigService.getScheduler();

    if (schedObj && schedObj[messageType.property]) {
      try {
        const decoded = atob(schedObj[messageType.property] as string);
        const jsonObj = JSON.parse(decoded);

        if (
          jsonObj &&
          jsonObj[messageType.key] &&
          jsonObj[messageType.key].active
        ) {
          const lang = this.languageService.getCurrentLanguage();
          if (jsonObj[messageType.key][lang]) {
            message = jsonObj[messageType.key][lang];
          }
        }
      } catch (e) {
        this.logger.error('Error parsing custom terms:', e);
      }
    }

    return message;
  }

  isMessageValid(messageType: { property: string; key: string }): boolean {
    const message = this.getContent(messageType);
    return message.trim() !== '';
  }
}
