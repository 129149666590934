import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgIf, NgClass, JsonPipe } from '@angular/common';

@Component({
  selector: 'eos-pe-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, JsonPipe],
})
export class ErrorMessageComponent {
  showError = false;
  @Input() genericMessage: string;
  @Input() errorMessage: HttpErrorResponse | null;

  getGenericErrorMessage(): string {
    return this.genericMessage
      ? this.genericMessage
      : 'An error occurred while trying to perform your query.';
  }

  getErrorResponse(): HttpErrorResponse | null {
    return this.errorMessage;
  }

  dismissError(): void {
    this.errorMessage = null;
  }

  toggleError(): void {
    this.showError = !this.showError;
  }
}
