import { Pipe, PipeTransform } from '@angular/core';

/**
 * The idea for this comes from https://vsplearning.udemy.com/course/angular-forms-course/learn/lecture/23627406#overview
 * Lesson #20. The idea is to use this pipe to prioritize which single error to show
 * when there may be multiple errors on a form control.
 */
@Pipe({
  name: 'onlyOneError',
  standalone: true,
})
export class OnlyOneErrorPipe implements PipeTransform {
  transform(allErrors: unknown, errorsPriority: string[]): unknown {
    if (!allErrors) {
      return null;
    }

    const foundErrorKey: string | undefined = errorsPriority.find(
      (error) => allErrors[error],
    );

    return foundErrorKey ? { [foundErrorKey]: allErrors[foundErrorKey] } : {};
  }
}
