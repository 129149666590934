import { Injectable } from '@angular/core';

/**
 * This class is a wrapper around the window object. It is used to access the window object in a way that can be
 * mocked for testing purposes.
 */
@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  get href(): string {
    return window.location.href;
  }

  reload(): void {
    window.location.reload();
  }
}
