<div id="errorMessage" class="mr-4">
  <div class="row errorColoring errorSpacing text-left errorText">
    <div class="col-10" *ngIf="errorMessage">
      <i class="fa fa-circle-o fa-lg circle-position"></i>
      <i class="fa fa-exclamation exclamation-position"></i>
      <p id="errorMessageText" class="col errorTextColor input-validation-text">
        {{ getGenericErrorMessage() }}&nbsp; Try again or contact
        <a
          target="_blank"
          href="mailto:ewebdev@vsp.com?subject={{
            genericMessage
          }}&body=Error Info: {{ errorMessage | json }}"
          >ewebdev&#64;vsp.com</a
        >
        if this issue persists.
      </p>

      <pre
        id="errorDetails"
        *ngIf="showError"
        class="errorTextColor noScrollForPre"
        >{{ errorMessage | json }}</pre
      >
    </div>
    <div class="col-2 text-right detailsColor" (click)="toggleError()">
      <div
        id="errorButton"
        [ngClass]="showError ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'"
        class="btn"
      >
        Error Details
      </div>
    </div>
  </div>
</div>
