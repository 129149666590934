// The triple-slash directive below is a TypeScript reference directive required to include type definitions from the @angular/localize package.
// This ensures that TypeScript is aware of the localization types used throughout this Angular application.
/// <reference types="@angular/localize" />

import {
  enableProdMode,
  ErrorHandler,
  APP_INITIALIZER,
  importProvidersFrom,
} from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { SharedModule } from './app/shared/shared.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication } from '@angular/platform-browser';
import { CoreModule } from './app/core/core.module';
import { LoggerModule } from 'ngx-logger';
import { AppInitService } from './app/app-init.service';
import { TransactionInterceptor } from './app/core/interceptor/transaction.intercepter';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Translations } from './app/core/services/translations.service';
import { SharedUtilityService } from './app/shared/utilities/shared-utility.service';
import { DatePipe } from '@angular/common';
import { GlobalErrorHandlerService } from './app/core/services/global-error-handler.service';
import { initializeApp, WebpackTranslateLoader } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      LoggerModule.forRoot({
        serverLoggingUrl: 'api/logs',
        level: environment.loggingLevel,
        serverLogLevel: environment.serverLoggingLevel,
        timestampFormat: environment.timestampFormat,
        disableConsoleLogging: environment.disableConsoleLogging,
      }),
      CoreModule,
      // BrowserModule,
      SharedModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: WebpackTranslateLoader,
        },
      }),
    ),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    DatePipe,
    SharedUtilityService,
    Translations,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransactionInterceptor,
      multi: true,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    provideAnimations(),
  ],
}).catch((err) => {
  console.error('Error during app initialization:', err);
  // To catch something like an error on initialization, such as the very real case of no 'domainName' in the querysting,
  // an error handling mechanism is needed.

  // Maybe just something like "window.location.href = '/error-page';" is enough...maybe not.
});
