import { Insurance } from './insurance/insurance';
import { AppointmentType } from './appointment-type';
import { Provider } from './provider';
import { OfficeAddress } from './office-address';
import { DisplayValueType } from '../display-value-type';

export class Office extends DisplayValueType {
  timeZone: number;
  usingDst: boolean;
  insurances: Array<Insurance>;
  appointmentTypes: Array<AppointmentType>;
  providers: Array<Provider>;
  staffs: Array<Provider>;
  isWebSchedulable: boolean;
  epmCompanyId: string;
  isElectronicNotifiable: boolean;
  officeAddress: OfficeAddress;
  insuranceRequired: string;
  defaultNoPreference: boolean;
  bypassPatientSearch: boolean;
  rescheduleAppts: number;
}
