<div class="button-grid-container">
  <button
    *ngIf="!hideBackButton"
    class="buttonBack"
    mat-stroked-button
    color="primary"
    (click)="backAction.emit()"
    tabindex="0"
  >
    {{ backLabel | translate }}
  </button>
  <button
    class="buttonContinue"
    mat-raised-button
    color="primary"
    (click)="continueAction.emit()"
    [disabled]="isContinueDisabled"
    tabindex="0"
  >
    {{ continueLabel | translate }}
  </button>
</div>
