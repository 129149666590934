import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LoggerUpdateService {
  constructor(private logger: NGXLogger) {}

  setDisableConsoleLogging(setting: boolean): void {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.disableConsoleLogging = setting;
    this.logger.updateConfig(updatedConfig);
  }

  // NgxLoggerLevel enum
  //   TRACE = 0,DEBUG = 1,INFO = 2,LOG = 3,WARN = 4,ERROR = 5,FATAL = 6,OFF = 7
  setLoggerLevel(setting: NgxLoggerLevel): void {
    const updatedConfig = this.logger.getConfigSnapshot();
    updatedConfig.level = setting;
    this.logger.updateConfig(updatedConfig);
  }

  enableBrowserLogging(): void {
    this.setLoggerLevel(NgxLoggerLevel.DEBUG);
    this.setDisableConsoleLogging(false);
  }
}
