// import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { AppRoutingModule } from './app-routing.module';
// import { AppComponent } from './app.component';
import { HttpClient } from '@angular/common/http';
// import { SharedUtilityService } from './shared/utilities/shared-utility.service';
// import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
// import { TransactionInterceptor } from './core/interceptor/transaction.intercepter';
// import { environment } from '../environments/environment';
import { NGXLogger } from 'ngx-logger';
// import { CoreModule } from './core/core.module';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SharedModule } from './shared/shared.module';
// import { DatePipe } from '@angular/common';

// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { Translations } from './core/services/translations.service';
// import { from, Observable, pluck, lastValueFrom } from 'rxjs';
// import { AppInitService } from './app-init.service';
// import { ErrorPageComponent } from './pages/error-page/error-page.component';
// import { ViewTitleHeaderComponent } from './shared/components/view-title-header/view-title-header.component';
// import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';
// import { PracticeHeaderComponent } from './shared/components/practice-header/practice-header.component';
// import { ShortMessageComponent } from './shared/components/short-message/short-message.component';
import { NgModule } from '@angular/core';
import { AppInitService } from './app-init.service';
import { from, lastValueFrom, Observable, pluck } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

export function initializeApp(
  appInitService: AppInitService,
): () => Promise<void> {
  return (): Promise<void> => lastValueFrom(appInitService.initialize());
}

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<string> {
    return from(import(`../assets/i18n/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule(/* TODO(standalone-migration): clean up removed NgModule class manually.
{
  declarations: [AppComponent],
  imports: [
    LoggerModule.forRoot({
      serverLoggingUrl: 'api/logs',
      level: environment.loggingLevel,
      serverLogLevel: environment.serverLoggingLevel,
      timestampFormat: environment.timestampFormat,
      disableConsoleLogging: environment.disableConsoleLogging,
    }),
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    PracticeHeaderComponent,
    ViewTitleHeaderComponent,
    ShortMessageComponent,
    SpinnerOverlayComponent,
    ErrorPageComponent,
  ],
  exports: [],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    DatePipe,
    SharedUtilityService,
    Translations,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransactionInterceptor,
      multi: true,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
} */)
export class AppModule {
  constructor(private logger: NGXLogger) {
    this.logger.debug('AppModule loaded');
  }
}

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
