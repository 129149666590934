import { CoreModule } from '../core.module';

/*
 * Guard to make sure CoreModule is imported only by one NgModule
 * If CoreModule is already imported by another module, throw an error
 */
export function throwIfAlreadyLoaded(parentModule: CoreModule): void {
  if (parentModule) {
    throw new Error(
      '%{moduleName} has already been loaded.  Import Core modules in the appModule only.',
    );
  }
}
