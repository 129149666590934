import { Injectable } from '@angular/core';

/**
 * Service to interact with the browser's local storage.
 *
 * The `localStorage` object is a built-in feature of the web browser and is part
 * of the Web Storage API. It does not need to be declared or imported.
 * It is globally available in the browser environment.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem(key: string, value: unknown): void {
    localStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value),
    );
  }

  getItem(key: string): string | null {
    const value = localStorage.getItem(key);
    return value;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
