import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UnderscoreToSpacePipe } from './pipes/underscore-to-space.pipe';

import { FormsModule } from '@angular/forms';
import { CapitalizeAndSpaceCamelCasePipe } from './pipes/capitialize.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { ConvertTime24hTo12hPipe } from './pipes/convert-time24h-to12h.pipe';

import { OnlyOneErrorPipe } from './pipes/only-one-error.pipe';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';

@NgModule({
  imports: [
    CommonModule,
    CheckboxComponent,
    ErrorMessageComponent,
    CapitalizeAndSpaceCamelCasePipe,
    PhoneNumberPipe,
    UnderscoreToSpacePipe,
    ConvertTime24hTo12hPipe,
    OnlyOneErrorPipe,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    UnderscoreToSpacePipe,
    CheckboxComponent,
    ErrorMessageComponent,
    CapitalizeAndSpaceCamelCasePipe,
    PhoneNumberPipe,
    ConvertTime24hTo12hPipe,
    OnlyOneErrorPipe,
  ],
  providers: [PhoneNumberPipe],
})
export class SharedModule {}
