import { AppEnvironment } from '../app/shared/model/app-environment';
import { AppEnvironmentName } from '../app/shared/model/app-environment-name.enum';
import { NgxLoggerLevel } from 'ngx-logger';
import { VERSION } from './version';
import { STAGING_API_URL } from '../app/shared/constants';

export const environment: AppEnvironment = {
  production: true,
  envName: AppEnvironmentName.STG,

  loggingLevel: NgxLoggerLevel.DEBUG,
  serverLoggingLevel: NgxLoggerLevel.OFF,
  disableConsoleLogging: false,

  version: VERSION,

  apiUrl: STAGING_API_URL + '/mt-pe-portal-api/',

  // Possible values: 'HH:mm:ss.SSS' (custom), ' ' (to remove); see also https://angular.io/api/common/DatePipe
  timestampFormat: 'HH:mm:ss.SSS',

  // for debugging and testing
  useDebugMode: false, // This will display dom values of components in the UI to debug potential sync issues
  mockDatabase: false, // This can be set to true to use subbed calls to the api
};
