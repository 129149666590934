<mat-form-field class="almost-full-width" appearance="outline">
  <mat-label>
    {{ label | translate }}
  </mat-label>

  <mat-select
    [ngModel]="selectedValue"
    (ngModelChange)="onSelectedValue($event)"
    [class.disabled]="disabled"
    [compareWith]="uniqueItemIdentifier"
    [disabled]="disabled"
    [required]="required"
    role="tablist"
  >
    <mat-option *ngFor="let item of values" [value]="item" role="tab">
      {{ item.label | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
