export class AppointmentRequestPatient {
  pmPatientId: string | null | undefined = null;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  address: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  zipCode: string | null | undefined;
  email: string | null | undefined;
  homePhone: string | null | undefined;
  phoneNumber: string | null | undefined;
  mobilePhone: string | null | undefined;
  mobileNumber: string | null | undefined;
  insuranceId: string | null | undefined;
  sendApptNotificationsViaText: boolean | null | undefined;
  sendApptNotificationsViaEmail: boolean | null | undefined;
  secureEmail: boolean | null | undefined;
  emailChanged: boolean | null | undefined;
  phoneChanged: boolean | null | undefined;
  gender: string | null | undefined;
  selectedCarrier: string | null | undefined;
  reviewRequired: boolean | null | undefined;
  dateOfBirth: string | null | undefined;
  officeLocationId: string | null | undefined;
}
