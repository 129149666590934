<div>
  <div class="services-section">
    <div class="grid" *ngIf="selectedLocation">
      <mat-card
        *ngFor="
          let apptType of selectedLocation.appointmentTypes;
          let i = index
        "
        [class.hidden]="
          !showAllServices && i >= itemsPerRow * maxRowsBeforeShowingToggle
        "
        [class.selected]="selectedServiceId === apptType.id"
        (click)="selectService(apptType)"
        (keydown)="handleKeydown($event, apptType)"
        class="grid-item card"
        tabindex="0"
      >
        <mat-card-content>
          <p>{{ apptType.displayName }}</p>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="shouldShowToggleBasedOnMaxRows" class="viewServicesToggle">
      <span
        class="viewServicesToggle"
        (click)="clickViewAllServicesToggle()"
        (keydown)="handleViewAllServicesKeydown($event)"
        tabindex="0"
      >
        {{ viewAllServicesToggleText }}
      </span>
    </div>
  </div>
</div>
