export const schedulerPath = 'scheduler/';

export enum RoutePage {
  LANDING_PAGE = 'landing-page',
  SITE_MESSAGE = 'site-message',
  LOCATION_LAYOUT = 'location-layout',
  SERVICE_VIEW = 'service-view',
  CONFIRM_DATE_TIME = 'confirm-date-time',
  PATIENT_INFO = 'patient-info',
  CONFIRMATION_VIEW = 'confirmation-view',
  FORM_PLACE = 'form-place',
}

export enum RoutePath {
  LANDING_PAGE = schedulerPath + RoutePage.LANDING_PAGE,
  SITE_MESSAGE = schedulerPath + RoutePage.SITE_MESSAGE,
  LOCATION_LAYOUT = schedulerPath + RoutePage.LOCATION_LAYOUT,
  SERVICE_VIEW = schedulerPath + RoutePage.SERVICE_VIEW,
  CONFIRM_DATE_TIME = schedulerPath + RoutePage.CONFIRM_DATE_TIME,
  PATIENT_INFO = schedulerPath + RoutePage.PATIENT_INFO,
  CONFIRMATION_VIEW = schedulerPath + RoutePage.CONFIRMATION_VIEW,
  FORM_PLACE = schedulerPath + RoutePage.FORM_PLACE,
}

export function getRoutePath(routePath: RoutePage): string {
  return `${schedulerPath}/${routePath}`;
}
