import { Component, OnInit } from '@angular/core';
import { Translations } from 'src/app/core/services/translations.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NavService } from 'src/app/core/services/nav.service';
import { AppConfigService } from '../../core/services/app-config.service';
import { AppointmentDetailsService } from 'src/app/core/services/appointment-details.service';
import { ILanguageModel } from '../../shared/model/ilanguage-model';
import { DropdownValueObject } from '../../shared/components/dropdown/dropdown-value-object';
import { AppointmentSelectionsComponent } from '../../scheduler/appointment-selections/appointment-selections.component';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { ButtonPairComponent } from '../../scheduler/button-pair/button-pair.component';
import { ServiceOptionsSectionComponent } from './service-options-section/service-options-section.component';
import { AppointmentTypesComponent } from '../../shared/components/appointment-types/appointment-types.component';
import { RoutePath } from '../../shared/enums/routes.enum';
import { NavSchedulingService } from '../../scheduler/services/nav-scheduling.service';

/**
 * This component allows the user to select a service from a list of available services.
 */
@Component({
  selector: 'eos-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.scss'],
  standalone: true,
  imports: [
    AppointmentTypesComponent,
    ServiceOptionsSectionComponent,
    ButtonPairComponent,
    NgIf,
    MatCard,
    MatButton,
    AppointmentSelectionsComponent,
    TranslateModule,
  ],
})
export class ServiceViewComponent implements OnInit {
  serviceViewPageLanguage: ILanguageModel['serviceView'];
  practiceCancelReschedule: boolean;

  constructor(
    private translations: Translations,
    private translateService: TranslateService,
    private navService: NavService,
    private appConfigService: AppConfigService,
    private appointmentDetailsService: AppointmentDetailsService,
    private navSchedulingService: NavSchedulingService,
  ) {}

  ngOnInit(): void {
    this.serviceViewPageLanguage = this.translations.serviceView;
    this.setViewTitle();

    this.setPracticeCancelReschedule();
  }

  setViewTitle(): void {
    const translatedTitle: string = this.translateService.instant(
      this.serviceViewPageLanguage.selectService,
    );
    this.appConfigService.setViewTitle(translatedTitle);
  }

  continueButtonAction(): void {
    if (!this.appointmentDetailsService.getInsuranceDVO()) {
      const newSelectedInsurance = new DropdownValueObject(
        '-1',
        'No Insurance',
      );
      this.appointmentDetailsService.setInsuranceDVO(newSelectedInsurance);
    }
    this.appointmentDetailsService.setUseLastServiceDetail(true);

    this.navService.navigateToPage(
      this.navSchedulingService.getNextRoute(RoutePath.SERVICE_VIEW),
    );
  }

  hideBackButton(): boolean {
    if (
      this.appointmentDetailsService.isLocationLocked ||
      this.appointmentDetailsService.isSingleLocation
    ) {
      return true;
    }
    return false;
  }

  backButtonAction(): void {
    this.appointmentDetailsService.setUseLastServiceDetail(false);

    this.navService.navigateToPage(
      this.navSchedulingService.getPreviousRoute(RoutePath.SERVICE_VIEW),
    );
  }

  private setPracticeCancelReschedule(): void {
    const portalObj = this.appConfigService.getPortal();
    this.practiceCancelReschedule = portalObj.practiceCancelReschedule;
  }
}
