import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { WindowRefService } from '../../shared/window-ref.service';
import { LocalStorageService } from './local-storage.service';
import { AppConfigService } from './app-config.service';
import { Office } from '../../shared/domains/scheduler/office/office';
import { IPreSelectedLocation } from '../../scheduler/interfaces/pre-selected-location.interface';

/**
 * Service to handle query string parameters.
 *
 * See the table in README.md for the possible query string parameters.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class QueryStringService {
  fullUrl: string;
  private queryString = '';
  private queryParams = new Map<string, string>();
  configKeysToSaveInStorage = [
    'domainName',
    'lang',
    'loc',
    'isLocked',
    'navLoc',
  ];

  constructor(
    private logger: NGXLogger,
    private windowRefService: WindowRefService,
    private localStorageService: LocalStorageService,
    private appConfigService: AppConfigService,
  ) {}

  initialize(): void {
    this.logger.debug('In QueryStringService.initialize()');

    // Save the url and parse its query string
    this.fullUrl = this.windowRefService.href;
    this.parseQueryString(this.fullUrl);

    // if 'domainName' is on the query string, treat this like a first-time visit
    // and remove any previously saved location. If there is a location on the query string,
    // it will be picked up in the next step.
    if (this.queryParams.has('domainName')) {
      this.localStorageService.removeItem('loc');
      this.localStorageService.removeItem('isLocked');
      this.localStorageService.removeItem('navLoc');
    }

    // save configKeysToSaveInStorage in localstorage
    this.saveConfigInStorage();
  }

  getDomainName(): string {
    return <string>this.getParamValue('domainName', true);
  }

  getLanguage(): string | null {
    return this.getParamValue('lang');
  }

  getNavToLocations(): boolean {
    return this.getParamValue('navLoc') !== null;
  }

  getPreSelectedLocation(): IPreSelectedLocation | null {
    const locationName = this.getParamValue('loc');
    if (locationName) {
      const matchingOffice: Office | null =
        this.findMatchingLocation(locationName);
      if (matchingOffice) {
        return {
          loc: locationName,
          isLocked: this.getParamValue('isLocked') === 'true',
          office: matchingOffice,
        };
      }
    }
    return null;
  }

  getParamValue(paramKey: string, isRequired = false): string | null {
    let value: string | undefined = this.queryParams.get(paramKey);
    if (!value) {
      value = this.localStorageService.getItem(paramKey) ?? undefined;
    }
    if (value) {
      this.logger.debug(`${paramKey}:`, value);
      return value;
    } else if (isRequired) {
      this.logger.error(`${paramKey} not found`);
      throw new Error(`${paramKey} not found`);
    } else {
      // For optional parameters, you might want to log a different message or nothing at all
      this.logger.info(`${paramKey} not found`);
      return null; // or return a default value if that makes sense for your application
    }
  }

  private findMatchingLocation(locationName: string): Office | null {
    const officeList: Office[] = [];
    const schedulerData = this.appConfigService.getScheduler();
    if (schedulerData.offices) {
      officeList.push(...schedulerData.offices);
      const matchingOffice: Office | undefined = officeList.find((office) =>
        this.matchTargetLocation(office.displayName, locationName),
      );

      if (matchingOffice) {
        this.logger.debug('preselected location', matchingOffice);
        return matchingOffice;
      } else {
        this.logger.error('No Location Matched');
      }
    }
    return null;
  }

  matchTargetLocation(displayName: string, targetName: string): boolean {
    return (
      displayName.replaceAll(' ', '').toLowerCase() ===
      targetName.replaceAll(' ', '').toLowerCase()
    );
  }

  private parseQueryString(hostUrl: string): void {
    // Extract the query string, if any
    const queryStringMatch = hostUrl.match(/\?([^#]*)/);
    this.queryString = queryStringMatch ? queryStringMatch[1] : '';

    // Parse the query string into key-value pairs
    this.queryParams = new Map<string, string>();
    if (this.queryString) {
      const queryParams = this.queryString.split('&');
      for (const keyValue of queryParams) {
        const [key, value] = keyValue.split('=');
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        this.queryParams.set(decodedKey, decodedValue);
      }
    }
    this.logger.debug('query string parameters are:', this.queryParams);
  }

  saveConfigInStorage(): void {
    this.configKeysToSaveInStorage.forEach((key) => {
      if (this.queryParams.has(key)) {
        this.localStorageService.setItem(key, this.queryParams.get(key));
      }
    });
  }
}
