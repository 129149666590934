import { ILanguageModel } from 'src/app/shared/model/ilanguage-model';

const es: ILanguageModel = {
  languageName: 'es',
  landingPage: {
    hi: 'Hola,',
    howWeCanHelpYouToday: 'Cómo podemos ayudarle hoy?',
    bookAnAppointment: 'Reservar una cita',
    rescheduleCancelAppointment: 'Reprogramar/Cancelar Cita',
    patientForms: 'Formularios Para Pacientes',
    next: 'Siguiente',
  },
  locationLayout: {
    hours: 'horas',
    miles: 'millas',
    selectLocation: 'Seleccione Ubicación',
  },
  serviceView: {
    selectService: 'Seleccionar Servicio',
    continue: 'Continuar',
    back: 'Atrás',
    alreadyHaveAnAppointment: 'Ya tengo una cita',
    cancelOrReviewYourAppointmentDetails:
      'Cancele o revise los detalles de su cita.',
    viewAppointment: 'Ver cita',
  },
  serviceOptions: {
    appointmentWithDoctor: 'Cita con',
    insuranceCarrier: 'Aseguradora',
    noPreferences: 'Sin preferencias',
  },
  siteMessage: {
    scheduleAppointment: 'Reservar Cita',
  },
  home: {
    fullOutPreVisitFormsOnline:
      'Complete los formularios previos a la visita en línea.',
    stayTunedForExcitingUpdates: 'Estén atentos a actualizaciones interesantes',
  },
  confirmDate: {
    searchingFirstAvailableAppointment:
      'Buscando la primera cita disponible...',
    selectDateAndTime: 'Seleccione Fecha y Hora',
    noAppointmentAvailable: 'No hay cita disponible',
    noResultsFound:
      'No hay citas disponibles en los próximos 6 meses. Vuelva atrás y haga otra selección o comuníquese con nuestra oficina para programar una cita.',
    continue: 'Continuar',
    back: 'Atrás',
    officePhone: 'Teléfono de la Oficina',
  },
  patientInfo: {
    yourInformation: 'Tu información',
    additionalInformation: 'Información adicional',
  },
  patientInfoForm: {
    firstName: 'Nombre',
    firstNameRequired: 'Nombre es requerido.',
    firstNameLength: 'Nombre debe tener entre 2 y 15 caracteres.',
    firstNamePattern:
      'Los nombres solo pueden contener letras, guiones, espacios y apóstrofes.',
    lastName: 'Apellido',
    lastNameRequired: 'Apellido es requerido.',
    lastNameLength: 'Apellido debe tener entre 2 y 25 caracteres.',
    lastNamePattern:
      'Los nombres solo pueden contener letras, guiones, espacios y apóstrofes.',
    address: 'Dirección',
    addressRequired: 'Dirección es requerida.',
    addressLength: 'Dirección debe tener entre 2 y 35 caracteres.',
    city: 'Ciudad',
    cityRequired: 'Ciudad es requerida.',
    cityLength: 'Ciudad debe tener entre 2 y 25 caracteres.',
    state: 'Estado',
    stateRequired: 'Estado es requerido.',
    zipCode: 'Código Postal',
    zipCodeRequired: 'Código Postal es requerido.',
    zipCodeLength: 'Código Postal debe tener 5 caracteres.',
    dateOfBirth: 'Fecha de Nacimiento',
    dateOfBirthRequired: 'Fecha de Nacimiento es requerida.',
    dateOfBirthNotValid: 'Ingrese una fecha de nacimiento válida',
    birthSex: 'Sexo de Nacimiento',
    email: 'Correo Electrónico',
    emailRequired: 'Correo Electrónico es requerido.',
    emailNotValid: 'Ingrese una dirección de correo electrónico válida',
    phoneNumberNotValid: 'Ingrese un número de teléfono válido',
    phoneNumber: 'Número de Teléfono',
    phoneNumberRequired: 'Número de Teléfono es requerido.',
    howWouldYouLikeToBeNotified: '¿Cómo le gustaría ser notificado?',
    textMe: 'Envíame un mensaje de texto',
    emailMe: 'Envíame un correo electrónico',
    insuranceId: 'ID de Seguro',
    bookAppointment: 'Reservar Cita',
    back: 'Atrás',
  },
  confirmation: {
    confirmation: 'Confirmación',
    appointmentBooked: 'Su cita ha sido reservada.',
    errorBookingAppointment:
      'No pudimos programar su cita. Por favor, póngase en contacto con la oficina.',
  },
  errorPage: {
    genericMessage:
      'Lo sentimos. Nuestros servicios están experimentando algunas dificultades en este momento. Por favor, llame directamente a la oficina.',
  },
  appointmentSelections: {
    locationSection: 'Sección de Ubicación',
    serviceSection: 'Sección de Servicio',
    appointmentTimeSection: 'Sección de Hora de Cita',
    change: 'Cambiar',
  },
};

export default es;
