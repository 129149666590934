import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LogTransactionService {
  logTransactionId = '';

  constructor(private logger: NGXLogger) {}

  getLogTransactionId(): string {
    if (this.logTransactionId === '') {
      this.createUUID();
    }
    return this.logTransactionId;
  }

  createUUID(): void {
    // add transaction id to the header; create it if necessary
    try {
      // TODO use https://github.com/uuidjs/uuid - "Generate RFC-compliant UUIDs in JavaScript"
      //   rabbit hole: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
      this.logTransactionId = UUID.UUID();
      this.logger.debug('createUUID:', this.logTransactionId);
    } catch (ex) {
      this.logger.error('createUUID failed', ex);
    }
  }
}
