import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfigService } from '../../../core/services/app-config.service';

@Component({
  selector: 'eos-practice-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './practice-header.component.html',
  styleUrl: './practice-header.component.scss',
})
export class PracticeHeaderComponent implements OnInit {
  practiceName = '';

  constructor(private appConfigService: AppConfigService) {}

  ngOnInit(): void {
    this.appConfigService
      .getPracticeName$()
      .subscribe((data) => (this.practiceName = data));
  }
}
