import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

/**
 * Back/Continue button pair component
 */
@Component({
  selector: 'eos-button-pair',
  templateUrl: './button-pair.component.html',
  styleUrl: './button-pair.component.scss',
  standalone: true,
  imports: [NgIf, MatButton, TranslateModule],
})
export class ButtonPairComponent {
  @Input() backLabel: string = 'Back';
  @Input() continueLabel: string = 'Continue';
  @Input() isContinueDisabled: boolean = false;
  @Input() hideBackButton: boolean = false;

  @Output() backAction = new EventEmitter<void>();
  @Output() continueAction = new EventEmitter<void>();
}
