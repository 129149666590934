import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SchedulerService } from 'src/app/scheduler/services/scheduler.service';
import { InsuranceDropdownVisibility } from 'src/app/shared/enums/insurance-dropdown-visibility.enum';
import { Office } from '../../../shared/domains/scheduler/office/office';
import { AppointmentRole } from '../../../shared/enums/appointment-role.enum';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentDetailsService } from '../../../core/services/appointment-details.service';
import { DropdownValueObject } from '../../../shared/components/dropdown/dropdown-value-object';
import { AppMatSelectComponent } from '../../../shared/components/dropdown/app-mat-select.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'eos-service-options-section',
  templateUrl: './service-options-section.component.html',
  styleUrls: ['./service-options-section.component.scss'],
  standalone: true,
  imports: [NgIf, AppMatSelectComponent],
})
export class ServiceOptionsSectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  insuranceDropdownVisibility = InsuranceDropdownVisibility;
  insurancesDropdown: DropdownValueObject[] = [];
  providersDropdown: DropdownValueObject[] = [];
  selectedInsurance: DropdownValueObject | null;
  selectedProvider: DropdownValueObject | null;
  private appointmentTypeSub: Subscription;
  insuranceCarrierLabel: string;
  appointmentWithDoctorLabel: string;
  unsubscribe: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private schedulerService: SchedulerService,
    private appointmentDetailsService: AppointmentDetailsService,
    private logger: NGXLogger,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // The appointmentType will affect the setup of the provider dropdown
    this.appointmentTypeSub = this.schedulerService.appointmentType$.subscribe(
      (apptType) => {
        this.logger.debug('Received appointment type:', apptType);

        this.handleAppointmentTypeChange(apptType);
      },
    );

    this.setupInsurerSelection();
  }

  ngOnDestroy(): void {
    // Unsubscribe when the component is destroyed
    this.appointmentTypeSub.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.getDropdownTranslatedLabel();
  }

  getDropdownTranslatedLabel(): void {
    this.translate
      .get('serviceOptions.insuranceCarrier')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.insuranceCarrierLabel = value;
      });

    this.translate
      .get('serviceOptions.appointmentWithDoctor')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.appointmentWithDoctorLabel = value;
      });

    this.cdr.detectChanges();
  }

  private handleAppointmentTypeChange(apptType): void {
    this.logger.debug('=== handleAppointmentTypeChange()', apptType);
    if (!apptType) {
      this.providersDropdown = [];
      return;
    }

    this.populateProvidersDropdown(apptType);
    this.setupProviderSelection();
  }

  populateProvidersDropdown(apptType): void {
    const location = this.appointmentDetailsService.getLocation();
    const providers =
      apptType.role === AppointmentRole.PROVIDER
        ? location.providers
        : location.staffs;

    this.providersDropdown = providers.map(
      (provider) => new DropdownValueObject(provider.id, provider.displayName),
    );
  }

  private setupInsurerSelection(): void {
    if (this.appointmentDetailsService.getLocation()) {
      const office: Office = this.appointmentDetailsService.getLocation();
      this.insurancesDropdown = office?.insurances?.map(
        (insurance) =>
          new DropdownValueObject(insurance.id, insurance.displayName),
      );

      if (
        this.appointmentDetailsService.getUseLastServiceDetail() &&
        this.appointmentDetailsService.getInsuranceDVO()
      ) {
        this.selectedInsurance =
          this.appointmentDetailsService.getInsuranceDVO();
        this.updateInsurance(this.selectedInsurance);
      }
    }
  }

  private setupProviderSelection(): void {
    // If there is only one provider, select it by default
    if (this.providersDropdown.length === 1) {
      this.selectedProvider = this.providersDropdown[0];
      this.updateProvider(this.providersDropdown[0]);
      return;
    }

    // Add a "No Preference" option to the dropdown
    this.translate
      .get('serviceOptions.noPreferences')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((translatedNoPreference: string) => {
        this.providersDropdown.unshift(
          new DropdownValueObject('-1', translatedNoPreference),
        );
      });

    this.selectInitialProvider();
  }

  private selectInitialProvider(): void {
    const lastSelectedProvider =
      this.appointmentDetailsService.getProviderDVO();

    // Select either the previously selected provider or the added 'No Preference' option
    if (this.checkIfProviderIsInDropdown(lastSelectedProvider)) {
      this.selectedProvider = lastSelectedProvider;
    } else {
      this.selectedProvider = this.providersDropdown[0];
    }

    this.updateProvider(this.selectedProvider);
  }

  checkIfProviderIsInDropdown(provider: DropdownValueObject): boolean {
    return !!this.providersDropdown.find(
      (item) => item.value === provider?.value,
    );
  }

  updateInsurance(newSelectedInsurance: DropdownValueObject): void {
    this.logger.debug('updateInsurance()', newSelectedInsurance);
    this.appointmentDetailsService.setInsuranceDVO(newSelectedInsurance);
    this.appointmentDetailsService.dumpAppointmentDetails();
  }

  updateProvider(selectedProvider: DropdownValueObject): void {
    this.logger.debug('updateLocation()', selectedProvider);
    this.appointmentDetailsService.setProviderDVO(selectedProvider);
    this.appointmentDetailsService.dumpAppointmentDetails();
  }

  locationInsuranceRequired(): string {
    if (
      !this.appointmentDetailsService.getLocation() ||
      !this.appointmentDetailsService.getLocation().insuranceRequired
    ) {
      return InsuranceDropdownVisibility.REQUIRED;
    } else {
      return this.appointmentDetailsService.getLocation().insuranceRequired;
    }
  }
}
