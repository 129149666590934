import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ILanguageModel } from '../../shared/model/ilanguage-model';
import en from '../../../assets/i18n/en';
import es from '../../../assets/i18n/es';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  // List of valid languages
  private languages: { en: ILanguageModel; es: ILanguageModel } = { en, es };
  private validLanguages = ['en', 'es'];

  // Default to English
  private languageSubject$ = new BehaviorSubject<string>('en');

  constructor(private translate: TranslateService) {}

  initializeLanguage(language: string | null): void {
    // Check if the provided language is valid, if not, default to 'en'
    const langToSet =
      language && this.validLanguages.includes(language) ? language : 'en';
    this.setLanguage(langToSet);
  }

  setLanguage(language: string): void {
    if (this.validLanguages.includes(language)) {
      this.languageSubject$.next(language);
      this.translate.use(language);
    } else {
      // If an invalid language is attempted to be set, default to English
      this.languageSubject$.next('en');
      this.translate.use('en');
    }
  }

  getCurrentLanguage(): string {
    return this.languageSubject$.getValue();
  }

  getLanguage(): Observable<string> {
    return this.languageSubject$.asObservable();
  }
}
