import { Injectable } from '@angular/core';
import { Office } from '../../shared/domains/scheduler/office/office';
import { AppointmentType } from '../../shared/domains/scheduler/office/appointment-type';
import { AppointmentTimeSlot } from '../../shared/model/appointment.model';
import { ApiAppointment } from '../../shared/domains/scheduler/api-appointment';
import { Appointment } from '../../shared/model/appointment.model';
import { PatientInfoFormData } from '../../shared/domains/scheduler/patient-info-form-data';
import { AppointmentRequestPatient } from '../../shared/domains/scheduler/appointment-request-patient';
import { NGXLogger } from 'ngx-logger';
import { DropdownValueObject } from '../../shared/components/dropdown/dropdown-value-object';
import { birthSex } from '../../shared/constants';

/**
 * This service holds details of the current appointment that is being booked, rescheduled, etc.
 */
@Injectable({
  providedIn: 'root',
})
export class AppointmentDetailsService {
  location: Office;
  service: AppointmentType;
  insuranceDVO: DropdownValueObject;
  provider: DropdownValueObject;
  appointmentTimeSlot: AppointmentTimeSlot;
  pmPatientId: string | null | undefined = null;
  bookedAppointment: ApiAppointment | null = null;
  useLastServiceDetail = false;
  appointment: Appointment;
  timeslot: string;
  patientFormData: PatientInfoFormData;
  isLocationPreselected: boolean = false;
  isLocationLocked: boolean = false;
  isSingleLocation: boolean | null = null;

  constructor(private logger: NGXLogger) {}

  buildAppointmentRequestPatient(): AppointmentRequestPatient {
    const apptRequestPatient = new AppointmentRequestPatient();
    const patientFormData: PatientInfoFormData = this.getPatientFormData();

    apptRequestPatient.pmPatientId = this.pmPatientId;
    apptRequestPatient.firstName = patientFormData.firstName;
    apptRequestPatient.lastName = patientFormData.lastName;
    apptRequestPatient.address = patientFormData.address;
    apptRequestPatient.city = patientFormData.city;
    apptRequestPatient.state = patientFormData.state;
    apptRequestPatient.zipCode = patientFormData.zipCode;
    apptRequestPatient.email = patientFormData.email;
    apptRequestPatient.homePhone = !patientFormData.textMe
      ? patientFormData.phoneNumber
      : null;
    apptRequestPatient.phoneNumber = !patientFormData.textMe
      ? patientFormData.phoneNumber
      : null;
    apptRequestPatient.mobilePhone = patientFormData.textMe
      ? patientFormData.phoneNumber
      : null;
    apptRequestPatient.mobileNumber = patientFormData.textMe
      ? patientFormData.phoneNumber
      : null;
    apptRequestPatient.insuranceId = patientFormData.insuranceId;
    apptRequestPatient.sendApptNotificationsViaText = patientFormData.textMe;
    apptRequestPatient.sendApptNotificationsViaEmail = patientFormData.emailMe;
    apptRequestPatient.secureEmail = patientFormData.emailMe;
    apptRequestPatient.emailChanged = false;
    apptRequestPatient.phoneChanged = false;
    apptRequestPatient.gender = patientFormData.birthSex;
    if (patientFormData.birthSex) {
      apptRequestPatient.gender = patientFormData.birthSex;
    } else {
      apptRequestPatient.gender = birthSex.DECLINE_TO_STATE;
    }
    apptRequestPatient.selectedCarrier = this.insuranceDVO.label;
    apptRequestPatient.reviewRequired = patientFormData.reviewRequired;
    apptRequestPatient.dateOfBirth = patientFormData.dateOfBirth;
    apptRequestPatient.officeLocationId = this.location.id;

    return apptRequestPatient;
  }

  getAppointmentRequestPatient(): AppointmentRequestPatient {
    const patientFormData: PatientInfoFormData = this.getPatientFormData();

    const apiPatientUser = new AppointmentRequestPatient();

    apiPatientUser.pmPatientId = null;
    apiPatientUser.officeLocationId = this.location.epmCompanyId;
    apiPatientUser.firstName = patientFormData.firstName;
    apiPatientUser.lastName = patientFormData.lastName;
    apiPatientUser.address = patientFormData.address;
    apiPatientUser.city = patientFormData.city;
    apiPatientUser.state = patientFormData.state;
    apiPatientUser.zipCode = patientFormData.zipCode;
    apiPatientUser.email = patientFormData.email;
    apiPatientUser.dateOfBirth = patientFormData.dateOfBirth;
    apiPatientUser.homePhone = !patientFormData.textMe
      ? patientFormData.phoneNumber
      : null;
    apiPatientUser.mobilePhone = patientFormData.textMe
      ? patientFormData.phoneNumber
      : null;
    apiPatientUser.emailChanged = false;
    apiPatientUser.phoneChanged = false;
    apiPatientUser.secureEmail = true;
    if (patientFormData.birthSex) {
      apiPatientUser.gender = patientFormData.birthSex;
    } else {
      apiPatientUser.gender = birthSex.DECLINE_TO_STATE;
    }
    apiPatientUser.selectedCarrier = patientFormData.insuranceId;
    apiPatientUser.reviewRequired = false;

    return apiPatientUser;
  }

  setLocation(office: Office): void {
    this.location = office;
  }
  getLocation(): Office {
    return this.location;
  }

  setService(appointmentType: AppointmentType): void {
    this.service = appointmentType;
  }
  getService(): AppointmentType {
    return this.service;
  }

  setInsuranceDVO(insuranceDVO: DropdownValueObject): void {
    this.insuranceDVO = insuranceDVO;
  }
  getInsuranceDVO(): DropdownValueObject {
    return this.insuranceDVO;
  }

  setProviderDVO(providerDVO: DropdownValueObject): void {
    this.provider = providerDVO;
  }
  getProviderDVO(): DropdownValueObject {
    return this.provider;
  }

  setUseLastServiceDetail(value: boolean): void {
    this.useLastServiceDetail = value;
  }
  getUseLastServiceDetail(): boolean {
    return this.useLastServiceDetail;
  }

  setAppointmentTimeSlot(appointmentTimeSlot: AppointmentTimeSlot): void {
    this.appointmentTimeSlot = appointmentTimeSlot;
  }
  getAppointmentTimeSlot(): AppointmentTimeSlot {
    return this.appointmentTimeSlot;
  }

  setBookedAppointment(appointment: ApiAppointment | null): void {
    this.bookedAppointment = appointment;
  }
  getBookedAppointment(): ApiAppointment {
    return this.bookedAppointment as ApiAppointment;
  }

  setAppointment(appointment: Appointment): void {
    this.appointment = appointment;
  }
  getAppointment(): Appointment {
    return this.appointment;
  }

  setTimeslot(timeslot: string): void {
    this.timeslot = timeslot;
  }
  getTimeslot(): string {
    return this.timeslot;
  }

  setPatientFormData(data: PatientInfoFormData): void {
    this.patientFormData = data;
  }
  getPatientFormData(): PatientInfoFormData {
    return this.patientFormData;
  }

  dumpAppointmentDetails(): void {
    this.logger.debug('======= Appointment Details =======');
    this.logger.debug('location: ', this.location);
    this.logger.debug('service: ', this.service);
    this.logger.debug('insuranceDVO: ', this.insuranceDVO);
    this.logger.debug('provider: ', this.provider);
    this.logger.debug('appointmentTimeSlot: ', this.appointmentTimeSlot);
    this.logger.debug('pmPatientId: ', this.pmPatientId);
    this.logger.debug('bookedAppointment: ', this.bookedAppointment);
    this.logger.debug('useLastServiceDetail: ', this.useLastServiceDetail);
    this.logger.debug('appointment: ', this.appointment);
    this.logger.debug('timeslot: ', this.timeslot);
    this.logger.debug('patientFormData: ', this.patientFormData);
  }
}
