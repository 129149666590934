<!-- 
<div class="appointment-selections">
  <h2>Location</h2>
  <br />
  <br />
  <h2>Service</h2>
  <br />
  <br />
  <h2>Appointment Time</h2>
  <br />
  <br />
</div> -->

<div class="appointment-selections" *ngIf="showLocation || showService">
  <!-- Location Section-->
  <div class="location-detail detail-section-item" *ngIf="showLocation">
    <div class="section-title">
      {{ appointmentSelectionsLanguage.locationSection | translate }}
    </div>
    <div class="section-subtitle">
      {{ schedulerService.getLocation().displayName }}
    </div>
    <div class="section-desc text-muted">
      {{ schedulerService.getOfficeAddress().address }}<br />
      {{ schedulerService.getOfficeAddress().city }}
    </div>
    <div *ngIf="!isLocationLocked">
      <a
        class="change-link-button"
        (click)="goToEditLocation()"
        *ngIf="appConfigService.getScheduler().offices.length > 1"
      >
        {{ appointmentSelectionsLanguage.change | translate }}
      </a>
    </div>
  </div>

  <!-- Service Section-->
  <div class="service-detail detail-section-item" *ngIf="showService">
    <div class="section-title">
      {{ appointmentSelectionsLanguage.serviceSection | translate }}
    </div>
    <div class="section-subtitle">
      {{ schedulerService.getServiceDetail().displayName }}
    </div>
    <div class="section-desc text-muted">
      With:
      <span class="section-provider">{{
        schedulerService.getProviderName()
      }}</span>

      <ng-container *ngIf="schedulerService.getInsuranceName()">
        <br />
        <span class="section-desc text-muted">
          Insurance:
          <span class="section-insurance">{{
            schedulerService.getInsuranceName()
          }}</span>
        </span>
      </ng-container>
    </div>
    <div>
      <a class="change-link-button" (click)="goToEditServiceDetail()">
        {{ appointmentSelectionsLanguage.change | translate }}
      </a>
    </div>
  </div>

  <!-- Appt Time Section-->
  <div
    class="appointment-detail detail-section-item"
    *ngIf="showAppointmentTime"
  >
    <div class="section-title">
      {{ appointmentSelectionsLanguage.appointmentTimeSection | translate }}
    </div>
    <div
      class="section-desc text-muted"
      *ngIf="schedulerService.getAppointmentTime()"
    >
      <span class="section-appointment">{{
        schedulerService.getAppointmentTime()
      }}</span>
    </div>
    <div>
      <a class="change-link-button" (click)="goToEditAppointmentDetail()">
        {{ appointmentSelectionsLanguage.change | translate }}
      </a>
    </div>
  </div>
</div>
