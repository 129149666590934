import { Pipe, PipeTransform } from '@angular/core';
import { SharedUtilityService } from '../utilities/shared-utility.service';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    return SharedUtilityService.formatPhoneNumber(value);
  }
}
