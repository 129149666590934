import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { Office } from '../../shared/domains/scheduler/office/office';
import { AppointmentType } from '../../shared/domains/scheduler/office/appointment-type';
import { OfficeAddress } from '../../shared/domains/scheduler/office/office-address';
import { AppointmentDetailsService } from '../../core/services/appointment-details.service';
import { DatePipe } from '@angular/common';
import { SharedUtilityService } from '../../shared/utilities/shared-utility.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private appointmentTypeSubject = new BehaviorSubject<
    AppointmentType | null | undefined
  >(this.appointmentDetailsService.getService());
  appointmentType$ = this.appointmentTypeSubject.asObservable();

  constructor(
    private logger: NGXLogger,
    private appointmentDetailsService: AppointmentDetailsService,
    private datePipe: DatePipe,
    private sharedUtility: SharedUtilityService,
  ) {}

  // When the appointment type changes, subscribers to 'appointmentType$' are notified.
  updateAppointmentType(appointmentType): void {
    this.logger.debug('updateAppointmentType', appointmentType);
    this.appointmentTypeSubject.next(appointmentType as AppointmentType);
  }

  getLocation(): Office {
    return this.appointmentDetailsService.getLocation() || new Office();
  }

  getServiceDetail(): AppointmentType {
    return this.appointmentDetailsService.getService() || new AppointmentType();
  }

  getOfficeAddress(): { address: string; city: string } {
    return {
      address: this.getLocation().officeAddress?.address1 || '',
      city: this.constructAddress(this.getLocation().officeAddress),
    };
  }

  getProviderName(): string {
    return this.appointmentDetailsService.getProviderDVO()?.label || '';
  }

  getInsuranceName(): string {
    return this.appointmentDetailsService.getInsuranceDVO()?.label || '';
  }

  getOfficePhoneNumber(): string {
    return this.getLocation().officeAddress?.phoneNumber || '';
  }

  getAppointmentTime(): string {
    const timeSlotIDParts = this.appointmentDetailsService
      .getTimeslot()
      ?.split(':');
    const slotData = this.appointmentDetailsService
      .getAppointment()
      ?.availableAppointmentTimeSlots.find(
        (e) => e.slotId === Number(timeSlotIDParts[1]),
      );
    if (!slotData) {
      return '';
    }

    return (
      this.datePipe.transform(timeSlotIDParts[0], 'mediumDate') +
      ' at ' +
      this.sharedUtility.formatTime(slotData.appointmentTime as string)
    );
  }

  private constructAddress(address: OfficeAddress): string {
    if (!address) return '';
    return `${address?.city}, ${address?.state} ${address?.zipCode?.substring(
      0,
      5,
    )}`;
  }
}
