export const LOCAL_API_URL = 'https://localhost:8443';
export const STAGING_API_URL = 'https://api-staging.eyefinity.com';
export const PRODUCTION_API_URL = 'https://api.eyefinity.com';

export const PORTAL_SCHEDULER_API = 'scheduler';
export const SCHEDULER_FREETIMES_API = 'freetimes';
export const SCHEDULER_PATIENT_SEARCH_API = 'searchPatient';
export const ADD_UNCONFIRMED_APPOINTMENT_API = 'addUnconfirmedAppointment';
export const BOOK_APPOINTMENT_API = 'addBookAppointment';

export const MAX_BOOKING_WINDOW_MONTHS = 6;

// This needs to be in sync with $breakpoint-mobile in styles-variables.scss
export const BREAKPOINT_MOBILE = 768;

// Errors
export const ERROR_PAGE_URL = '/error';
export const APPOINTMENT_BOOKING_ERROR = 'Appointment booking error';
export const TIME_SLOT_NO_LONGER_AVAILABLE = 'Time slot no longer available';
export const HTTP_STATUS_CONFLICT = 409;

export const NO_INSURANCE_VALUE = '-1';
export const VSP_PREFIX: string = 'VSP';
export const VSP_BENEFITS_LINK: string =
  'https://www.vsp.com/benefits/how-to-use-vsp-benefits';
export const VSP_DIRECT_LINK: string = 'https://www.vspdirect.com/';

export const pmPatientId = 'PatientId';

/**
 *  appointment-types.component.ts
 */
export const VIEW_ALL_SERVICES = 'View All Services';
export const SHOW_FEWER_SERVICES = 'Show Fewer Services';

/**
 * Scheduler Routing Constants
 */
export const PARTIAL_ROUTES_SCHEDULER = 'scheduler';

// Static data
// In javascript, months are 0-based
export const Months = {
  JAN: 0,
  FEB: 1,
  MAR: 2,
  APR: 3,
  MAY: 4,
  JUN: 5,
  JUL: 6,
  AUG: 7,
  SEP: 8,
  OCT: 9,
  NOV: 10,
  DEC: 11,
};
export const birthSexes = [
  { id: 'MALE', displayName: 'Male' },
  { id: 'FEMALE', displayName: 'Female' },
];
export enum birthSex {
  'DECLINE_TO_STATE' = 'DECLINE_TO_STATE',
  'MALE' = 'MALE',
  'FEMALE' = 'FEMALE',
}

export const STATES = [
  { id: 1, abbr: 'AL', displayName: 'Alabama' },
  { id: 2, abbr: 'AK', displayName: 'Alaska' },
  { id: 3, abbr: 'AZ', displayName: 'Arizona' },
  { id: 4, abbr: 'AR', displayName: 'Arkansas' },
  { id: 5, abbr: 'CA', displayName: 'California' },

  { id: 6, abbr: 'CO', displayName: 'Colorado' },
  { id: 7, abbr: 'CT', displayName: 'Connecticut' },
  { id: 8, abbr: 'DE', displayName: 'Delaware' },
  { id: 9, abbr: 'DC', displayName: 'District of Columbia' },
  { id: 10, abbr: 'FL', displayName: 'Florida' },

  { id: 11, abbr: 'GA', displayName: 'Georgia' },
  { id: 12, abbr: 'HI', displayName: 'Hawaii' },
  { id: 13, abbr: 'ID', displayName: 'Idaho' },
  { id: 14, abbr: 'IL', displayName: 'Illinois' },
  { id: 15, abbr: 'IN', displayName: 'Indiana' },

  { id: 16, abbr: 'IA', displayName: 'Iowa' },
  { id: 17, abbr: 'KS', displayName: 'Kansas' },
  { id: 18, abbr: 'KY', displayName: 'Kentucky' },
  { id: 19, abbr: 'LA', displayName: 'Louisiana' },
  { id: 20, abbr: 'ME', displayName: 'Maine' },

  { id: 21, abbr: 'MD', displayName: 'Maryland' },
  { id: 22, abbr: 'MA', displayName: 'Massachusetts' },
  { id: 23, abbr: 'MI', displayName: 'Michigan' },
  { id: 24, abbr: 'MN', displayName: 'Minnesota' },
  { id: 25, abbr: 'MS', displayName: 'Mississippi' },

  { id: 26, abbr: 'MO', displayName: 'Missouri' },
  { id: 27, abbr: 'MT', displayName: 'Montana' },
  { id: 28, abbr: 'NE', displayName: 'Nebraska' },
  { id: 29, abbr: 'NV', displayName: 'Nevada' },
  { id: 30, abbr: 'NH', displayName: 'New Hampshire' },

  { id: 31, abbr: 'NJ', displayName: 'New Jersey' },
  { id: 32, abbr: 'NM', displayName: 'New Mexico' },
  { id: 33, abbr: 'NY', displayName: 'New York' },
  { id: 34, abbr: 'NC', displayName: 'North Carolina' },
  { id: 35, abbr: 'ND', displayName: 'North Dakota' },

  { id: 36, abbr: 'OH', displayName: 'Ohio' },
  { id: 37, abbr: 'OK', displayName: 'Oklahoma' },
  { id: 38, abbr: 'OR', displayName: 'Oregon' },
  { id: 39, abbr: 'PA', displayName: 'Pennsylvania' },
  { id: 40, abbr: 'PR', displayName: 'Puerto Rico' },

  { id: 41, abbr: 'RI', displayName: 'Rhode Island' },
  { id: 42, abbr: 'SC', displayName: 'South Carolina' },
  { id: 43, abbr: 'SD', displayName: 'South Dakota' },
  { id: 44, abbr: 'TN', displayName: 'Tennessee' },
  { id: 45, abbr: 'TX', displayName: 'Texas' },

  { id: 46, abbr: 'UT', displayName: 'Utah' },
  { id: 47, abbr: 'VT', displayName: 'Vermont' },
  { id: 48, abbr: 'VA', displayName: 'Virginia' },
  { id: 49, abbr: 'WA', displayName: 'Washington' },
  { id: 50, abbr: 'WV', displayName: 'West Virginia' },

  { id: 51, abbr: 'WI', displayName: 'Wisconsin' },
  { id: 52, abbr: 'WY', displayName: 'Wyoming' },
  { id: 53, abbr: 'AA', displayName: 'AA' },
  { id: 54, abbr: 'AE', displayName: 'AE' },
  { id: 55, abbr: 'AP', displayName: 'AP' },

  { id: 56, abbr: 'AS', displayName: 'American Samoa' },
  { id: 57, abbr: 'GU', displayName: 'Guam' },
  { id: 58, abbr: 'FM', displayName: 'Micronesia' },
  { id: 59, abbr: 'MH', displayName: 'Marshall Islands' },
  { id: 60, abbr: 'PW', displayName: 'Palau' },

  { id: 61, abbr: 'VI', displayName: 'Virgin Islands' },
];
