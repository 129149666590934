<div id="practice-header" class="container practice-header">
  <div class="inner-container">
    <eos-practice-header></eos-practice-header>
  </div>
</div>

<div id="short-message" class="container short-message">
  <div class="inner-container">
    <eos-short-message></eos-short-message>
  </div>
</div>

<div
  id="view-title"
  class="container view-title"
  *ngIf="viewTitle && viewTitle.length > 0"
>
  <div class="inner-container">
    <!-- Pass viewTitle to the child component -->
    <eos-view-title-header [viewTitle]="viewTitle"></eos-view-title-header>
  </div>
</div>

<div id="router-outlet-content" class="container router-outlet-content">
  <div class="inner-container">
    <router-outlet></router-outlet>
  </div>
</div>
