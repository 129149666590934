import { Component, Input, OnInit } from '@angular/core';
import { SchedulerService } from '../services/scheduler.service';
import { Translations } from '../../core/services/translations.service';
import { NavService } from '../../core/services/nav.service';
import { AppConfigService } from '../../core/services/app-config.service';
import { ILanguageModel } from '../../shared/model/ilanguage-model';
import { AppointmentDetailsService } from '../../core/services/appointment-details.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { RoutePath } from '../../shared/enums/routes.enum';

/**
 * This is a side panel that shows the selected appointment details. It appears only on desktop.
 */
@Component({
  selector: 'eos-appointment-selections',
  templateUrl: './appointment-selections.component.html',
  styleUrls: ['./appointment-selections.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class AppointmentSelectionsComponent implements OnInit {
  appointmentSelectionsLanguage: ILanguageModel['appointmentSelections'];
  isLocationLocked: boolean = false;

  constructor(
    public schedulerService: SchedulerService,
    private translations: Translations,
    private navService: NavService,
    public appConfigService: AppConfigService,
    private appointmentDetailsService: AppointmentDetailsService,
  ) {}

  @Input() showService = false;
  @Input() showLocation = false;
  @Input() showAppointmentTime = false;

  ngOnInit(): void {
    this.appointmentSelectionsLanguage =
      this.translations.appointmentSelections;
    this.isLocationLocked = this.appointmentDetailsService.isLocationLocked;
  }

  goToEditLocation(): void {
    this.navService.navigateToPage(RoutePath.LOCATION_LAYOUT);
  }

  goToEditServiceDetail(): void {
    this.navService.navigateToPage(RoutePath.SERVICE_VIEW);
  }

  goToEditAppointmentDetail(): void {
    this.navService.navigateToPage(RoutePath.CONFIRM_DATE_TIME);
  }
}
