import { Component, OnInit } from '@angular/core';
import { Office } from '../../domains/scheduler/office/office';
import { SHOW_FEWER_SERVICES, VIEW_ALL_SERVICES } from '../../constants';
import { SchedulerService } from 'src/app/scheduler/services/scheduler.service';
import { AppointmentDetailsService } from '../../../core/services/appointment-details.service';
import { AppointmentType } from '../../domains/scheduler/office/appointment-type';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'eos-appointment-types-section',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MatCard, MatCardContent],
})
export class AppointmentTypesComponent implements OnInit {
  private toggleTextShow: string = VIEW_ALL_SERVICES;
  private toggleTextHide: string = SHOW_FEWER_SERVICES;

  selectedLocation: Office = new Office();
  selectedServiceId = '';
  viewAllServicesToggleText: string = this.toggleTextShow;
  showAllServices = false; // Start with items beyond 2nd row hidden
  itemsPerRow = 2; // Fixed to 2 items per row
  maxRowsBeforeShowingToggle = 2;
  shouldShowToggleBasedOnMaxRows = false;

  constructor(
    private schedulerService: SchedulerService,
    private appointmentDetailsService: AppointmentDetailsService,
  ) {}

  ngOnInit(): void {
    if (this.appointmentDetailsService.getLocation()) {
      const location = this.appointmentDetailsService.getLocation();

      this.selectedLocation = location;

      //  Auto-selecting the first appointment service by default.
      if (location.appointmentTypes.length > 0) {
        if (
          this.appointmentDetailsService.getService() &&
          this.appointmentDetailsService.getUseLastServiceDetail()
        ) {
          this.selectService(this.appointmentDetailsService.getService());
        } else {
          this.selectService(location.appointmentTypes[0]);
        }
      } else {
        throw new Error('No appointment types found for location');
      }
    }

    this.shouldShowToggleBasedOnMaxRows =
      this.updateShouldShowToggleBasedOnMaxRows();
  }

  clickViewAllServicesToggle(): void {
    this.showAllServices = !this.showAllServices;
    if (this.showAllServices) {
      this.viewAllServicesToggleText = this.toggleTextHide;
    } else {
      this.viewAllServicesToggleText = this.toggleTextShow;
    }

    this.shouldShowToggleBasedOnMaxRows =
      this.updateShouldShowToggleBasedOnMaxRows();
  }
  handleViewAllServicesKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.clickViewAllServicesToggle();
    }
  }

  updateShouldShowToggleBasedOnMaxRows(): boolean {
    return (
      this.selectedLocation &&
      this.selectedLocation.appointmentTypes &&
      this.selectedLocation.appointmentTypes.length >
        this.itemsPerRow * this.maxRowsBeforeShowingToggle
    );
  }

  selectService(service: AppointmentType): void {
    this.selectedServiceId = service.id;
    this.appointmentDetailsService.setService(service);
    this.schedulerService.updateAppointmentType(service);
    this.appointmentDetailsService.dumpAppointmentDetails();
  }

  handleKeydown(event: KeyboardEvent, apptType: AppointmentType): void {
    if (event.key === 'Enter') {
      this.selectService(apptType);
    }
  }
}
