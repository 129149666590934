import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SharedUtilityService {
  isMobile = false;

  constructor(
    private logger: NGXLogger,
    private deviceService: DeviceDetectorService,
    private datePipe: DatePipe,
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  getStartOfWeek(date: Date): Date {
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek;
    const startOfWeek = new Date(date);
    startOfWeek.setDate(diff);

    return startOfWeek;
  }

  // Return a string representation of a date as mm-dd-yyyy.
  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'MM-dd-yyyy') || '';
  }

  // Return a string representation of a time as 8:00 AM | 4:00 PM.
  formatTime(timeString: string): string {
    // Assuming timeString is in HH:mm format

    const timeArray = timeString.split(':');
    const hours = parseInt(timeArray[0], 10);
    const minutes = parseInt(timeArray[1], 10);

    const dummyDate = new Date(2000, 0, 1, hours, minutes);

    const formattedTime = this.datePipe.transform(dummyDate, 'h:mm a');

    return formattedTime || '';
  }

  // like "Friday, January 1, 2021 at 8:00 AM"
  formatFullDateTime(dateString: string): string {
    try {
      const date = new Date(dateString);
      return (
        this.datePipe.transform(date, 'fullDate') +
        ' at ' +
        this.datePipe.transform(date, 'h:mm a')
      );
    } catch (error) {
      this.logger.error('Error parsing date:', error);
      return '';
    }
  }

  buildMapLink(location): string {
    return encodeURI(
      'https://www.google.com/maps/search/?api=1&query=' + location,
    );
  }

  // Format phone number
  static formatPhoneNumber(value: string): string {
    if (!value) {
      return value;
    } else {
      const trimString = value.trim().replace('+1', '');
      switch (trimString.length) {
        case 7: {
          return trimString.slice(0, 3) + '-' + trimString.slice(3);
        }
        case 10: {
          return (
            '(' +
            trimString.slice(0, 3) +
            ') ' +
            trimString.slice(3, 6) +
            '-' +
            trimString.slice(6)
          );
        }
        default:
          return trimString;
      }
    }
  }
}
